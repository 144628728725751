@font-face {
  font-family: "source-serif-pro";
  src: url("/fonts/source-serif-pro-400-normal.woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "source-serif-pro";
  src: url("/fonts/source-serif-pro-700-normal.woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "sohne";
  src: url("/fonts/sohne-500-normal.woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "sohne";
  src: url("/fonts/sohne-700-normal.woff");
  font-weight: bold;
  font-style: normal;
}

body {
  -webkit-font-smoothing: auto;
  -moz-font-smoothing: auto;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: auto;
  text-rendering: optimizeLegibility;
  font-smooth: always;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  padding: 0;
  margin: 0;
  line-height: 140%;
}
button {
  font-family: "sohne";
  font-weight: normal;
}

/* beckon effect on hover */
.beckon {
  transition: all 0.2s;
  cursor: pointer;
}
.beckon:hover {
  transform: scale(1.1);
}
.beckon:active {
  transform: none;
  transition: none;
}

/* basic editor styles */
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }
}

.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

/* Give a remote user a caret */
.collaboration-cursor__caret {
  position: relative;
  margin-left: -1px;
  margin-right: -1px;
  border-left: 1px solid #0d0d0d;
  border-right: 1px solid #0d0d0d;
  word-break: normal;
  pointer-events: none;
}

/* Render the username above the caret */
.collaboration-cursor__label {
  position: absolute;
  top: -1.4em;
  left: -1px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  user-select: none;
  color: #0d0d0d;
  padding: 0.1rem 0.3rem;
  border-radius: 3px 3px 3px 0;
  white-space: nowrap;
}

.ProseMirror:focus {
  outline: none;
}

/** used for plain template **/
#app button:first-of-type {
  margin-bottom: 30px;
}

#app input {
  margin: 10px;
  display: block;
}

#app button {
  margin: 10px;
  display: block;
}

.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }
}

.bubble-menu {
  display: flex;
  background-color: #0d0d0d;
  padding: 0.2rem;
  border-radius: 0.5rem;

  button {
    border: none;
    background: none;
    color: #fff;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0 0.2rem;
    opacity: 0.6;

    &:hover,
    &.is-active {
      opacity: 1;
    }
  }
}

.floating-menu {
  display: flex;
  background-color: #0d0d0d10;
  padding: 0.2rem;
  border-radius: 0.5rem;

  button {
    border: none;
    background: none;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0 0.2rem;
    opacity: 0.6;

    &:hover,
    &.is-active {
      opacity: 1;
    }
  }
}

/* custom css */
body {
  background-color: #fff;
  font-family: source-serif-pro;
}
.header {
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  background-color: #fff;
}
.header .headerRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
  margin: 0;
  text-align: right;
  padding: 2rem;
  width: 100%;
  user-select: none;
}
.header .headerRight li {
  display: inline-block;
  color: #555;
  user-select: none;
  transition: all 0.2s;
  white-space: nowrap;
}
.logo {
  font-size: 1.1rem;
  color: #000;
  font-weight: bold;
  white-space: nowrap;
  padding: 2rem;
}
.logo a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
  transition: all 0.2s;
}

.museButtonWrapper {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: fixed;
  bottom: 2rem;
  z-index: 999;
  width: 10rem;
  left: 50%;
  margin-left: -5rem;
}
.museButton {
  width: 7rem;
  color: #fcdf3b;
  background-color: #000;
  text-align: center;
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: center;
  border-radius: 10rem;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.2s;
  user-select: none;
  gap: 0.5rem;
}
.museButton:not(.loading):hover {
  transform: scale(1.05);
}
.museButton:not(.loading):active {
  transition: none;
  transform: scale(1);
}
.museButton.loading {
  color: #000;
  background-color: #eee;
  cursor: default;
}
.museButton.loading img {
  display: inline-block;
  width: 1.8rem;
}

.content {
  padding: 4rem 0;
}

.settings {
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  list-style-type: none;
  margin: 0 0 1rem;
}
.settings > li + li {
  margin-top: 1rem;
}
.settings label {
  display: block;
  user-select: none;
}
.settings textarea.musePrompt {
  width: 100%;
  height: 8rem;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  padding: 0.5rem;
  font-size: 1rem;
  font-family: inherit;
  resize: vertical;
  margin-top: 0.5rem;
}
.settings .checkbox {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.users {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 0;
}
.users > li {
  display: flex;
  gap: 0.25rem;
  flex-wrap: nowrap;
  align-items: center;
}
.users > li {
  margin-right: 1rem;
}
.users > li:last-child {
  margin-right: 0;
}
.users .avatar {
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  background-color: #fff;
}
.users span {
  font-size: 0.9rem;
}

.toaster {
  opacity: 0;
  background-color: #222 !important;
  color: #fff !important;
  align-items: flex-start !important;
  gap: 0.5rem;
  width: auto !important;
  max-width: 1000px !important;
}
.toaster .toasterMessage {
  padding: 1rem;
  line-height: 140%;
  font-size: 1.1rem;
}
.toaster .toasterMessage div[role="status"] {
  margin: 0 !important;
}
.toaster .toasterSep {
  border-left: 1px solid #fff;
  opacity: 0.2;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  height: 1rem;
}
.toaster .toasterButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 0;
}
.toaster button {
  border: none;
  font-size: 0.8em;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  padding: 0.25em 0.5em;
  border-radius: 0.25em;
  display: block;
  cursor: pointer;
}
.toaster .toasterActions {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  transition: 0.2s transform;
}
.toaster .toasterActions button {
  user-select: none;
  color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  gap: 0.25rem;
}
.toaster .toasterActions button span {
  display: block;
  font-size: 0.9rem;
}
.toaster .toasterActions button:hover {
  color: #fff;
}
.toaster .toasterFeedback {
  font-size: 0.9rem;
  color: #ccc;
}
.toaster .toasterTip {
  font-size: 0.8em;
  opacity: 0.5;
  margin-top: 1rem;
}
@keyframes custom-enter {
  0% {
    opacity: 0;
    transform: translateY(10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes custom-exit {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(10%);
  }
}

.editor {
  margin: 2rem auto;
  min-height: 80vh;
  max-width: 1000px;
  color: #000;
  position: relative;
  display: flex;
  z-index: 1;
  border-radius: 0.5rem;
  user-select: none;
  margin-bottom: 500px;
}
.editor > div:first-child {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.editor .ProseMirror {
  // position: absolute;
  width: 100%;
  padding: 1.5rem 2rem;
  box-sizing: border-box;
  min-height: 15rem;
  line-height: 150%;
  font-size: 1.1rem;
  font-size: 1.3rem;
  height: 100%;
}
.editor code {
  background-color: rgba(241, 166, 141, 0.12);
  padding: 0.15em 0.35em;
  border-radius: 0.5em;
}
.editor .ProseMirror pre code {
  font-size: 1.1rem;
}
.editor .mention {
  border: 1px solid #000;
  border-radius: 0.4rem;
  box-decoration-break: clone;
  padding: 0.1rem 0.3rem;
}
.editor a {
  color: #555;
}
.editor img {
  max-width: 100%;
}
.editor h1 {
  margin-top: 0rem;
  font-family: sohne, Arial, Helvetica, sans-serif;
  line-height: 140%;
}

/* modals */
.react-responsive-modal-root {
  z-index: 10000 !important;
}
.react-responsive-modal-modal {
  border-radius: 0.5rem;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  min-width: 200px;
}
.react-responsive-modal-modal h2 {
  color: #000;
}
.react-responsive-modal-closeButton {
  outline: none;
}
.react-responsive-modal-modal p {
  font-size: 1.1rem;
  line-height: 140%;
}

// styles for smaller screens
@media (max-width: 768px) {
  .content {
    margin-top: 1rem;
  }
  .header {
    display: none;
  }
  .toaster {
    flex-wrap: wrap;
    max-width: 100% !important;
    justify-content: right;
    font-size: 0.9rem;
  }
  .toaster .toasterButtons {
    order: 0;
    font-size: 1.2rem;
  }
  .toaster .toasterMessage {
    order: 1;
    padding: 0;
  }
}
